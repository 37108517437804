import {
  type Brand,
  type BrandResponse,
  type CategoryBySlugResponse,
} from '../category';
import { type AttachmentResponse, type Pager } from '~/infrastructure';
import { type Price } from '~/utils';
import { type LicenceType, type WarehouseResponse } from '~/domains/core';
import { type ProductRegion } from '~/domains/product';
import { type Anchor } from '~/ui/UiTooltip/types.d';
import { type ProductVarious } from './entities/ProductVarious';

export enum ProductDeliveryStatuses {
  SHIPPED = 'Shipped',
  ACCEPTED = 'Accepted',
  IN_TRANSIT = 'In Transit',
}

export interface ProductMark {
  color: string;
  text: string;
}

export interface ProductPrice {
  actualPrice: Price;
  retail: Price | null;
  beforeDiscount: Price | null;
}

export interface ProductWarranty {
  warrantyTerms?: string;
  warrantyTermsCustomer?: string;
}

type ProductActionType =
  | 'QUANTITY'
  | 'TEXT'
  | 'EVENT'
  | 'CREDIT_DAYS'
  | 'MORNING_STAR'
  | 'WEEKEND'
  | 'SUPER_PRICE'
  | 'GIFT'
  | 'BANNER'
  | 'COMN'
  | 'QUDIS'
  | 'BUNDLE'
  | 'ROOT';

export type ProductActionIconType =
  | 'SPECIAL_OFFER'
  | 'HOT'
  | 'SALE'
  | 'NEW'
  | 'OTHER'
  | 'SUPER_PRICE'
  | 'GIFT'
  | 'NONE'
  | 'FRAR'
  | 'COMN'
  | 'QUDIS'
  | 'ROOT'
  | 'EXCLUSIVE';

export type ProductActionPriceType = 'PRODUCT_DISCOUNT' | 'QTY_DISCOUNT';
export type ProductGradeResponse = 'N' | 'S' | 'Q' | 'A' | 'B' | 'C' | 'D';
export type ProductGrade = 'A+' | 'BN' | 'S' | 'A' | 'B' | 'C' | 'D';
export type CatalogViews = 'gridWithoutPhotos' | 'gridWithPhotos' | 'card';

export interface CatalogDisplayView {
  icon: string;
  label: string;
  view: CatalogViews;
}

export interface ProductActionProductType {
  article: string;
  condition: string;
}
export interface ProductActionResponse {
  id: number;
  type: ProductActionType;
  name: string;
  display_size: string;
  display_type: string;
  icon_type: ProductActionIconType;
  icon_visible: boolean;
  priority: string;
  hover_text?: string;
  html_preview: string;
  html_body: string;
  date_from: string;
  date_till?: string;
  main_page: boolean;
  url: string;
  target: string;
  products: ProductActionProductType[];
  action_product_price_type?: ProductActionPriceType;
  action_price: number;
  estimated_price1: string;
  estimated_price2: string;
  estimated_price3: string;
  qty1: string;
  price_fixed1: string;
  discount1: string;
  discount_percent1: string;
  qty2: string;
  price_fixed2: string;
  discount2: string;
  discount_percent2: string;
  qty3: string;
  price_fixed3: string;
  discount3: string;
  discount_percent3: string;
}

export interface ProductAction {
  id: number;
  type: ProductActionType;
  name: string;
  iconType: ProductActionIconType;
  isIconVisible: boolean;
  hoverText?: string;
  color: string;
  label: string;
  dateTill?: Date;
  actionProductPriceType?: ProductActionPriceType;
  quantity1: string;
  quantity2: string;
  quantity3: string;
  priceFixed1: Price | string;
  priceFixed2: Price | string;
  priceFixed3: Price | string;
  discount1: Price | string;
  discount2: Price | string;
  discount3: Price | string;
  discountPercent1: string;
  discountPercent2: string;
  discountPercent3: string;
  estimatedPrice1: Price | string;
  estimatedPrice2: Price | string;
  estimatedPrice3: Price | string;
}

export interface ProductStockResponse {
  type: 'LOCAL' | 'EXTENDED' | 'TRANSIT';
  available: 'NO' | 'LIMITED' | 'AVAILABLE';
  stock: string;
  warehouse?: WarehouseResponse;
  delivery_hours: string;
  shipped_date: string;
  estimated_delivery_date: string;
  delivery?: string;
  details: ProductStockResponse[];
}

export interface ProductStockWarehouse {
  id: number;
  name: string;
}

export interface ProductStock {
  type: 'LOCAL' | 'EXTENDED' | 'TRANSIT' | string;
  available: 'NO' | 'LIMITED' | 'AVAILABLE';
  stock: string;
  deliveryHours?: string;
  shippedDate?: Date;
  estimatedDeliveryDate?: Date;
  delivery?: string;
  details?: ProductStock[];
  warehouse?: ProductStockWarehouse;
  serializedType?: string;
  outputText?: string;
}

/**
 * sm - 60px
 * md - 150px
 * lg - 270px
 * xl - 900px
 */
export interface ProductImage {
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  alt?: string;
}

export interface Product {
  id: number | null;
  title?: string;
  sku?: string;
  stock?: ProductStock | null;
  image?: ProductImage;
  slug?: string;
  price?: ProductPrice;
  equipment?: string;
  warranty?: ProductWarranty;
  quantity?: {
    ordered: string | number;
  };
  logistic?: {
    weight?: string;
    packsInBox?: string;
  };
  actions?: ProductAction[];
  ean?: string | null;
  brand?: string | null;
  currency: string | null;
  licenseType?: LicenceType;
  keyAttributes?: ProductKav[];
  lineId?: string;
  various?: ProductVarious[];
  grade?: ProductGradeResponse;
  haveGradeStockInfo?: boolean;
  regions?: ProductRegion[];
}

export interface ProductRegionResponse {
  code?: string;
  countries?: string[];
}

export interface ProductImageResponse {
  url: string;
}
export interface ProductVariousValueResponse {
  value: string;
}
export interface ProductVariousResponse {
  type: string;
  values: ProductVariousValueResponse[];
}
export interface ProductResponse {
  article: string;
  condition: string;
  ean: string;
  qty?: number;
  prfxd: string;
  basket_qty: string;
  nid: string;
  slug: string;
  opid: string;
  cfid: string;
  brand_id: string;
  brand: string;
  pt_id: string;
  pt: string;
  description: string;
  warranty: {
    warranty_terms: string;
    warranty_terms_customer: string;
  };
  thumbnail: string;
  price: string;
  retail: string;
  levy: string;
  additional_tax_type: string;
  price_before_discount: string;
  vat_percent: string;
  actions: ProductActionResponse[];
  stock: ProductStockResponse;
  logistic: {
    weight: string;
    packs_in_box: string;
  };
  qty_req: string;
  price_req: string;
  weight: string;
  packs_in_box: string;
  images?: {
    small: ProductImageResponse;
    small_thumbnail: ProductImageResponse;
    big_thumbnail: ProductImageResponse;
    big: ProductImageResponse;
  };
  currency: string;
  license_type: LicenceType;
  key_attributes?: ProductKavResponse[];
  various: ProductVariousResponse[];
  grade?: ProductGradeResponse;
  have_grade_stock_info?: boolean;
  regions?: ProductRegionResponse[];
}

export interface ProductNodeResponse {
  id: number;
  name: string;
  qty: number;
  selected: boolean;
  children: ProductNodeResponse[];
}

export interface ProductNode {
  id: string;
  name: string;
  quantity: number;
  selected: boolean;
  children: ProductNode[];
}

export interface ProductSubnodeResponse {
  id: number;
  name: string;
  qty: number;
  selected: boolean;
}

export interface ProductSubnode {
  id: string;
  name: string;
  quantity: number;
  selected: boolean;
}

export interface ProductTypeResponse {
  id: string;
  name: string;
  qty: number;
  selected: boolean;
}

export interface ProductType {
  id: string;
  name: string;
  quantity: number;
  selected: boolean;
}

export interface ProductKavValueResponse {
  kav_id: string;
  qty: number;
  rv: number;
  selected: boolean;
  value: string;
  articles?: string[];
}

export interface ProductKavResponse {
  ka_id: string;
  name: string;
  values: ProductKavValueResponse[];
}

export interface ProductFilterBrandResponse {
  id: string;
  name: string;
}

export interface ProductFilterActionResponse {
  type: string;
  qty: number;
  selected: boolean;
}

interface ProductFilterVariousItemResponse {
  value: string;
  qty: number;
  selected: boolean;
}

export interface ProductFilterVariousResponse {
  type: string;
  values: ProductFilterVariousItemResponse[];
}

export interface ProductFilterResponse {
  nid: string;
  brand_id?: string;
  brand?: ProductFilterBrandResponse;
  stock?: Array<{
    kind: string;
    qty: number;
    selected: boolean;
  }>;
  nodes?: ProductNodeResponse[];
  subnodes?: ProductSubnodeResponse[];
  brands: BrandResponse[];
  pts: ProductTypeResponse[];
  kavs: ProductKavResponse[];
  price: {
    min: string;
    max: string;
  };
  action: string;
  actions?: ProductFilterActionResponse[];
  various?: ProductFilterVariousResponse[];
}

export interface ProductKavValue {
  id: string;
  quantity: number;
  realValue: number;
  value: string;
  productArticle: string | null;
}

export interface ProductKav {
  id: string;
  name: string;
  values: ProductKavValue[];
}

export interface ProductActionFilter {
  type: string;
  quantity: number;
  selected: boolean;
}

interface ProductFilterVariousItem {
  value: string;
  quantity: number;
  selected: boolean;
}
export interface ProductFilterVarious {
  type: string;
  values: ProductFilterVariousItem[];
}

export interface ProductFilterType {
  categoryId: string;
  brandId: string | null;
  stock?: Array<{
    kind: string;
    quantity: number;
    selected: boolean;
  }>;
  nodes?: ProductNode[];
  subnodes?: ProductSubnode[];
  brands: Brand[];
  productTypes: ProductType[];
  keyAttributes: ProductKav[];
  price?: {
    min: string;
    max: string;
  };
  action: string | null;
  actions: ProductActionFilter[];
  various?: ProductFilterVarious[];
}

export interface ProductTopMainResponse extends CategoryBySlugResponse {}

export interface ProductTopMainRequestQuery {
  pager: Pager;
}

export interface ProductFeatureResponse {
  feature: string;
  benefit: string;
}

export type ProductImageTypes =
  | 'small'
  | 'small_thumbnail'
  | 'big_thumbnail'
  | 'big';

export type ProductImagesResponse = {
  [key in ProductImageTypes]: ProductImageResponse[];
};

export type ProductSpecificationResponse = Record<
  string,
  ProductSpecificationValueResponse[]
>;

export interface ProductSpecificationValueResponse {
  name: string;
  value: string;
  code: string;
}

export interface ProductCertificateResponse {
  name: string;
  url: string;
  content_type_code: string;
}

export interface ProductLineManagerResponse {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  picture_id: string;
  picture_url: string;
  phone: string;
  mobile: string;
  fax: string;
}

export interface ProductVideoResponse {
  article: string;
  iso_lang: string;
  play_time: string;
  description: string;
  url: string;
  kind: string;
}

export interface ProductLinkResponse {
  article: string;
  description: string;
  iso_lang: string;
  kind: string;
  url: string;
}

export interface ProductInfoResponse {
  product: ProductResponse;
  description?: string;
  content?: string;
  features: ProductFeatureResponse[];
  documents?: AttachmentResponse[];
  certificates?: ProductCertificateResponse[];
  images?: ProductImagesResponse;
  specification?: ProductSpecificationResponse;
  products?: ProductResponse[];
  plm?: ProductLineManagerResponse;
  kavs: ProductKavResponse[];
  videos?: ProductVideoResponse[];
  links?: ProductLinkResponse[];
}

export interface ProductCatalogActionButton {
  id: number;
  title: string;
  icon: string;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
  tooltipDisabled: boolean;
  tooltipLocation: Anchor;
  tooltipDefaultText: string;
  tooltipActionText?: string;
  isEnableCollapseButton?: boolean;
  show: boolean;
  isVisible: boolean;
}

export type ProductViewVariants =
  | 'card'
  | 'gridWithPhotos'
  | 'gridWithoutPhotos';
