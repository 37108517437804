import {
  type ProductRegion,
  type ProductRegionResponse,
  productRegionSerializer,
} from '~/domains/product';

export default function productRegionsSerializer(
  data?: ProductRegionResponse[],
): ProductRegion[] {
  return data?.map((region) => productRegionSerializer(region)) ?? [];
}
