import { ref, type Ref } from 'vue';
import { defineStore } from 'pinia';
import { type Product } from '~/domains/product';
import { type Basket } from '../../entities';
import { useBasketStatsStore } from './';

export default defineStore('basket', () => {
  const basketProductLines: Ref<Product[]> = ref([]);
  const basketLicenceLines: Ref<Product[]> = ref([]);
  const lastAddedLicenseType: Ref<boolean> = ref(false);
  const loading = ref(false);

  const { updateBasketStats } = useBasketStatsStore();

  const updateBasketProductLines = (products: Product[]): void => {
    basketProductLines.value = products;
  };

  const updateBasketLicenceLines = (licences: Product[]): void => {
    basketLicenceLines.value = licences;
  };

  const updateLastAddedLicenseType = (payload: 'ESD' | undefined): void => {
    lastAddedLicenseType.value = payload === 'ESD';
  };

  const updateBasket = (basket: Basket): void => {
    updateBasketProductLines(basket.products);
    updateBasketStats(basket.details);
    updateBasketLicenceLines(basket.licences);
  };

  const setLoading = (value: boolean): void => {
    loading.value = value;
  };

  const clearBasketLines = (): void => {
    updateBasketProductLines([]);
    updateBasketLicenceLines([]);
  };

  return {
    basketProductLines,
    updateBasketProductLines,
    setLoading,
    loading,
    updateBasket,
    updateBasketLicenceLines,
    updateLastAddedLicenseType,
    lastAddedLicenseType,
    basketLicenceLines,
    clearBasketLines,
  };
});
