<template>
  <UiDialog
    :model-value="modelValue"
    title="components.choseYourCompanyDialog.title"
    width="472"
    scrollable
    :loading="loading"
    data-test="header-modal-company"
    @update:model-value="handleModalValueUpdate"
  >
    <UiSearch
      :model-value="searchingValue"
      :disabled="loading"
      :label="t('common.buttons.Search.text')"
      icon
      @update:model-value="onSearchChange"
    />
    <UiList :disabled="loading">
      <UiListItem
        v-for="company in searchItemsByValue"
        :key="company.id"
        :value="company.id"
        data-test="header-modal-company-item"
        @click="handleClick(company.id)"
      >
        <UiListItemTitle>
          {{ company.name }}
        </UiListItemTitle>

        <template v-if="isActive(company.id)" #append>
          <UiIcon icon="$check" color="primary" />
        </template>
      </UiListItem>
    </UiList>
  </UiDialog>
</template>

<script setup lang="ts">
import { computed, type Ref, ref } from 'vue';
import {
  UiDialog,
  UiList,
  UiListItem,
  UiListItemTitle,
  UiSearch,
  UiIcon,
} from '~/ui';
import { useDomainRedirect, useI18n } from '~/infrastructure';
import useUserStore from '~/domains/user/user.store';
import {
  useChangeUserSettingsAdapter,
  getUserInfoViewModel,
} from '~/domains/user';
import useUserSearch from '~/domains/user/composables/useUserSearch';
import { storeToRefs } from 'pinia';

export interface Props {
  modelValue: boolean;
}

defineProps<Props>();

const emit = defineEmits<(e: 'update:modelValue', value: boolean) => void>();

const { t } = useI18n();
const { user } = storeToRefs(useUserStore());
const { fetchUserInfo } = getUserInfoViewModel();
const companies = computed(() => user.value?.companies);

const { searchingValue, searchItemsByValue, onSearchChange } =
  useUserSearch(companies);

const isActive = (id: string): boolean => {
  return user.value?.selected?.customerId === id;
};

const loading: Ref<boolean> = ref(false);
const { changeUserSettings } = useChangeUserSettingsAdapter();
const { handleRedirectToAnotherDomain } = useDomainRedirect();

const handleClick = async (id: string): Promise<void> => {
  try {
    loading.value = true;

    const response = await changeUserSettings({
      companyId: id,
    });

    searchingValue.value = '';

    await fetchUserInfo();
    loading.value = false;
    emit('update:modelValue', false);

    if (response?.shopUrl !== undefined) {
      handleRedirectToAnotherDomain(response.shopUrl, response.token ?? '');
    }
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
};

const handleModalValueUpdate = (value: boolean): void => {
  emit('update:modelValue', value);
  searchingValue.value = '';
};
</script>
