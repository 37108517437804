<template>
  <AppHeaderLayout
    :item="company"
    label="header.info.company.label"
    :items="companies"
    :country-code="countryCode"
    :city="city"
    data-test="header-company"
    @click="chooseCompanyModalState = true"
  />
  <UserModalChooseCompany v-model="chooseCompanyModalState" />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { type UserCompany, UserModalChooseCompany } from '~/domains/user';
import AppHeaderLayout from '~/app/layouts/header/components/AppHeaderLayout.vue';

export interface Props {
  company: string;
  companies: UserCompany[];
  countryCode: string;
  city: string;
}

defineProps<Props>();
const chooseCompanyModalState = ref(false);
</script>
