import { type ProductVariousResponse } from '../../types';
import type { ProductVarious } from '../../entities/ProductVarious';

const productVariousItemSerializer = (
  item: ProductVariousResponse,
): ProductVarious => {
  return {
    type: item.type,
    values: item.values,
  };
};

export default function productVariousSerializer(
  various?: ProductVariousResponse[],
): ProductVarious[] {
  return various?.map(productVariousItemSerializer) ?? [];
}
