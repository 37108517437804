import {
  type ProductRegion,
  type ProductRegionResponse,
} from '~/domains/product';

export default function productRegionSerializer(
  data?: ProductRegionResponse,
): ProductRegion {
  return {
    code: data?.code,
    countries: data?.countries,
  };
}
