import { ofetch } from 'ofetch';
import { useAuthStore } from '~/domains/auth';

const API_URL = import.meta.env.VITE_API_URL;

const api = ofetch.create({
  baseURL: API_URL,
  onRequest: ({ options }) => {
    const prevUrl =
      window.history.state.back !== null
        ? `${window.location.origin}${window.history.state.back}`
        : '';

    const authToken = localStorage.getItem('authToken');

    const newHeaders: Record<string, string> = {
      'x-original-location': window.location.href,
      'x-original-referer': prevUrl,
      ...(authToken !== '' &&
        typeof authToken === 'string' && { Authorization: authToken }),
    };

    Object.entries(newHeaders).forEach(([key, value]) => {
      options.headers.set(key, value);
    });
  },
  onResponseError: async (error) => {
    if (
      error.response.status === 403 &&
      window.location.pathname !== '/login'
    ) {
      const { setAuthToken } = useAuthStore();
      localStorage.removeItem('authToken');
      setAuthToken(null);
      window.location.href = '/login';
    }

    await Promise.reject(error);
  },
});

export default api;
