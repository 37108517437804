import productActionSerializer from './product-action.serializer';
import productStockSerializer from './product-stock.serializer';
import { type ProductResponse, type Product } from '../types';
import { Price } from '~/utils';
import productKavsSerializer from '../infrastructure/serializers/product-kavs.serializer';
import productVariousSerializer from '../infrastructure/serializers/product-various.serializer';
import { productRegionsSerializer } from '~/domains/product';

export default function productSerializer(data?: ProductResponse): Product {
  const result: Product = {
    id: data?.opid != null ? +data.opid : null,
    title: data?.description,
    sku: data?.article,
    stock:
      data?.stock !== undefined ? productStockSerializer(data?.stock) : null,
    image: {
      sm: data?.images?.small?.url ?? data?.thumbnail,
      md: data?.images?.small_thumbnail?.url ?? data?.thumbnail,
      lg: data?.images?.big_thumbnail?.url ?? data?.thumbnail,
      xl: data?.images?.big?.url ?? data?.thumbnail,
      alt: data?.article,
    },
    slug: data?.slug,
    price: {
      actualPrice: new Price(data?.price ?? ''),
      retail: data?.retail !== undefined ? new Price(data?.retail) : null,
      beforeDiscount:
        data?.price_before_discount !== undefined
          ? new Price(data?.price_before_discount)
          : null,
    },
    warranty: {
      warrantyTerms: data?.warranty?.warranty_terms,
      warrantyTermsCustomer: data?.warranty?.warranty_terms_customer,
    },
    equipment: data?.packs_in_box,
    quantity: {
      ordered: data?.qty === undefined ? '1' : data?.qty.toString(),
    },
    logistic: {
      weight: data?.logistic?.weight,
      packsInBox: data?.logistic?.packs_in_box,
    },
    actions:
      data?.actions?.map((action) => productActionSerializer(action)) ?? [],
    ean: data?.ean ?? null,
    brand: data?.brand ?? null,
    currency: data?.currency ?? null,
    licenseType: data?.license_type,
    keyAttributes:
      data?.key_attributes?.map((kav) => productKavsSerializer(kav)) ?? [],
    various: productVariousSerializer(data?.various),
    grade: data?.grade,
    regions: productRegionsSerializer(data?.regions),
    haveGradeStockInfo: data?.have_grade_stock_info,
  };

  if (
    result.stock?.details?.find((item) => item.type === 'LOCAL') === undefined
  ) {
    result.stock?.details?.unshift({
      type: 'LOCAL',
      stock: '—',
      available: 'NO',
    });
  }
  return result;
}
