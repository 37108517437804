import { useDateFormatter } from '~/infrastructure';

export default function dateSerializer(
  date?: Date | string,
  time: boolean = false,
): Date | undefined {
  const { handleDateFromApi } = useDateFormatter();

  return date != null ? handleDateFromApi(date, time) : undefined;
}
