<template>
  <AppHeaderLayout
    :item="selectedSupplier"
    :items="suppliers"
    :country-code="countryCode"
    label="header.info.supplier.label"
    data-test="header-supplier"
    @click="chooseSupplierModalState = true"
  />
  <UserModalChooseSupplier v-model="chooseSupplierModalState" />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import UserModalChooseSupplier from '~/domains/user/components/UserModal/UserModalChooseSupplier.vue';
import AppHeaderLayout from '~/app/layouts/header/components/AppHeaderLayout.vue';
import { type UserSupplier } from '~/domains/user';

export interface Props {
  selectedSupplier: string;
  countryCode: string;
  suppliers: UserSupplier[];
}

defineProps<Props>();
const chooseSupplierModalState = ref(false);
</script>
