<template>
  <VTextField
    ref="input"
    :model-value="modelValue"
    :label="label"
    :placeholder="placeholder"
    :type="type"
    :base-color="baseColor"
    :readonly="readonly"
    :density="density"
    :variant="variant"
    :single-line="singleLine"
    :error-messages="errorMessages"
    :hide-details="hideDetails"
    :disabled="disabled"
    :clearable="clearable"
    :bg-color="bgColor"
    :active="active"
    :inputmode="inputmode"
    :hint="hint"
    :persistent-hint="persistentHint"
    :max-width="maxWidth"
    :min-width="minWidth"
    :width
    :rounded
    class="field"
    @update:model-value="$emit('update:modelValue', $event)"
    @focus="handleFocus"
    @blur="handleBlur"
    @mouseup="handleFocus"
  >
    <template #append-inner>
      <slot name="append-inner" />
    </template>
    <template #prepend-inner>
      <slot name="prepend-inner" />
    </template>
  </VTextField>
</template>

<script setup lang="ts">
import { ref, nextTick } from 'vue';
import { VTextField } from 'vuetify/components/VTextField';
import { type Rounded, type Density } from '~/infrastructure';

export interface Props {
  modelValue: any;
  label?: string;
  placeholder?: string;
  type?: string;
  baseColor?: string;
  readonly?: boolean;
  density?: Density;
  singleLine?: boolean;
  hideDetails?: 'auto' | boolean;
  disabled?: boolean;
  clearable?: boolean;
  bgColor?: string;
  errorMessages?: string | string[];
  active?: boolean;
  variant?:
    | 'outlined'
    | 'plain'
    | 'underlined'
    | 'filled'
    | 'solo'
    | 'solo-inverted'
    | 'solo-filled';
  inputmode?: string;
  selectOnFocus?: boolean;
  hint?: string;
  persistentHint?: boolean;
  width?: number | string;
  maxWidth?: number | string;
  minWidth?: number | string;
  rounded?: Rounded;
}
const props = withDefaults(defineProps<Props>(), {
  baseColor: 'grey-200',
  label: '',
  placeholder: '',
  type: 'text',
  variant: 'outlined',
  density: 'default',
  singleLine: false,
  hideDetails: false,
  clearable: false,
  bgColor: 'white',
  active: false,
  disabled: false,
  errorMessages: '',
  inputmode: 'text',
  selectOnFocus: false,
  hint: '',
  persistentHint: false,
  width: undefined,
  maxWidth: undefined,
  minWidth: undefined,
  rounded: 'xl',
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'focus', value: Event): void;
  (e: 'blur', value: Event): void;
  (e: 'focus'): void;
  (e: 'blur'): void;
}>();

const input = ref();

const focus = (): void => {
  nextTick(() => {
    input.value.focus();
  });
};

const blur = (): void => {
  nextTick(() => {
    input.value.blur();
  });
};

const handleFocus = (event: Event): void => {
  const target = event.target as HTMLInputElement | HTMLTextAreaElement;
  if (
    target != null &&
    props.selectOnFocus &&
    typeof target.select === 'function'
  ) {
    setTimeout(() => {
      target.select();
    }, 0);
  }

  emit('focus');
};

const handleBlur = (): void => {
  emit('blur');
};

defineExpose({
  focus,
  blur,
});
</script>

<style lang="scss" scoped>
.field {
  font-size: 14px;

  &.v-input--error:not(.v-input--disabled) {
    :deep(.v-input__details .v-messages) {
      color: rgb(var(--v-theme-red-200));
    }
  }

  &.v-input--disabled {
    :deep(.v-field__overlay) {
      background-color: rgb(var(--v-theme-grey-50));
    }

    :deep(.v-field__outline) {
      color: rgb(var(--v-theme-grey-200));
    }
  }

  :deep(.v-field) {
    &.v-field--dirty,
    &.v-field--focused {
      .v-field__outline {
        --v-field-border-width: 1px;
      }
      .v-label {
        color: rgb(var(--v-theme-grey-600)) !important;
      }
    }

    &.v-field--focused {
      .v-field__outline {
        color: rgb(var(--v-theme-blue-700)) !important;
      }
    }

    &.v-field--error:not(.v-field--disabled) {
      .v-field__outline {
        color: rgb(var(--v-theme-red-200)) !important;
        --v-field-border-width: 1px;
      }
      .v-label {
        color: rgb(var(--v-theme-red-200)) !important;
      }
    }
  }
}

:deep(.v-field__outline) {
  --v-field-border-opacity: 1;
}

:deep(
  .v-field:not(.v-field--focused, .v-field--dirty) .v-field__field .v-label
) {
  font-size: 14px;
}

:deep(.v-label) {
  opacity: 1;
  color: rgb(var(--v-theme-grey-400));
}

:deep(.v-field__input) {
  font-size: 14px !important;
  color: rgb(var(--v-theme-grey-800));
  ::placeholder {
    color: rgb(var(--v-theme-grey-400));
    opacity: 1;
  }
}

:deep(.v-field--active.v-field--variant-outlined) {
  .v-field__outline {
    .v-field__outline__notch::before {
      opacity: 0;
    }
  }
}

:deep(.v-sheet) {
  color: rgb(var(--v-theme-grey-200));
}

:deep(.v-input__details) {
  padding-top: var(--spacing-base);
  padding-left: var(--spacing-3);
}

:deep(.v-messages) {
  .v-messages__message {
    white-space: pre-line;
  }
}

:deep(input) {
  -webkit-appearance: none;
}

:deep(.v-field--prepended) {
  padding-inline-start: 8px;
}

:deep(.v-field--appended) {
  padding-inline-end: 8px;
}

:deep(.v-field.v-field--appended) {
  --v-field-padding-end: 8px;
}

:deep(.v-field.v-field--prepended) {
  --v-field-padding-start: 8px;
}
</style>
